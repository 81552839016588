<template>
  <div class="welcome">
    <h1>Welcome to Kappelhoj.com</h1>
    <h3>Hello! This is my website</h3>
    <h3>Currently i have nothing to put here. You can checkout my Github or Linkedin in the footer</h3>
  </div>
</template>

<script>
export default {
  name: 'HomeRoot',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.welcome{
    text-align: center;
}

h1{
  margin-bottom: 2em;
}
</style>
